.ant-layout-header {
    background-color: transparent;
    color: #001E3A;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: max-content;
    padding: 0 16px 0 16px;
}
.ant-space-align-center {
    justify-content: space-around;
    width: 100%;
    padding-top: 6%;
}
.ant-btn-primary {
    width: fit-content;
}
.ant-btn-primary:hover {
    background-color: transparent !important;
    color: #1890ff !important;
    border-color: #1890ff;
}
.header-title {
    margin: auto;
    color: #001E3A !important;
    width: 90%;
    font-size: 30px !important;
    font-family: 'DM Sans';
}
.video-header {
    max-width: 65%;
    border-radius: 16px;
    margin-top: 3%;
}
.ant-btn-primary {
    font-family: 'DM Sans';
}
@media only screen and (min-width: 768px) {
    .header-title {
        margin: auto;
        color: #001E3A !important;
        width: 70%;
        font-size: 34px !important;
    }
}
  