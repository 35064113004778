#demo-container {
    padding: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.ant-list {
    width: 100%;
    margin-top: 32px;
}
.ant-list-item {
    border-block-end: none !important;
    min-height: 150px;
}
.demo-avatar { 
    width: 60px;
    height: 60px;
    box-shadow: 0 4px 36px 0 rgba(0, 0, 0, 0.2) !important;
    background-color: #1890ff !important;
    border: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.demo-avatar > img {
    width: 24px;
    height: 24px;
    margin: auto;
}
.ant-list-item-meta-title {
    text-transform: uppercase;
    color: #001E3A !important;
    font-family: 'DM Sans';
    font-size: 18px !important;
}
.ant-list-item-meta-description {
    font-size: 18px !important;
    color: #3E566C !important;
    font-family: 'DM Sans';
    text-align: justify;
    width: 90%;
}
.ant-list-items {
    display: flex;
    justify-content: space-between;
    min-height: 500px;
    flex-direction: column;
}
@media  only screen and (min-width: 1024px) {
    #demo-container {
        flex-direction: row;
        padding: 16px 56px 16px 56px;
    }
    #demo-main-container {
        padding-top: 4%;
    }
}