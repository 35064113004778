html {
    overflow-x: hidden;
}
body {
    background-image: url('./images/ellipseBg.svg');
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: 135% -2%;
    overflow-x: hidden;
}
@media only screen and (min-width: 768px) {
    body {
        background-image: url('./images/backgroundImage.svg');
        background-size: 98%;
        background-repeat: no-repeat;
        background-position: 135% -5%;
        overflow-x: hidden;
    }
}
