#action-main-container {
    padding-top: 0;
    background-color: #F7FBFF;
    justify-content: center;
    align-items: center;
}
#action-container {
    padding: 48px;
}
.action-content-texts {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.section-caption-center {
    width: 100%;
    text-align: center;
    color: #001E3A !important;
    font-family: 'DM Sans';
}
@media only screen and (min-width: 1024px) {
    .section-caption-center {
        width: 80%;
    }
}