.section-title {
    text-transform: uppercase;
    font-size: 20px;
    color: #1890ff !important;
    font-weight: 500 !important;
    text-align: center;
    font-family: 'DM Sans';
}
.section-caption {
    text-align: center;
    color: #001E3A !important;
    font-family: 'DM Sans';
    font-size: 26px !important;
    width: 80%;
    margin: auto;
}
.ant-card {
    background-color: #1890ff;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3) !important;
    max-width: 80%;
    margin: auto;
    height: 400px;
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
}
.ant-card-body {
    display: flex;
    align-items: center;
    justify-content: center;
}
.ant-card:hover {
    background-color: transparent !important;
    border: 2px solid #1890ff;
    box-shadow: none !important;
}
.ant-card-meta {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.ant-avatar {
    width: 100px;
    height: 100px;
    box-shadow: 0 4px 36px 0 rgba(0, 0, 0, 0.2) !important;
    background-color: white !important;
    border: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ant-avatar > img { 
    width: 58px;
    height: 58px;
    margin: auto;
}
.ant-card-meta-description {
    font-size: 18px !important;
    text-align: center !important;
    color: #001E3A !important;
    font-weight: 500 !important;
    font-family: 'DM Sans';
}
.ant-card-meta-detail {
    margin-top: 52px;
}
.card-box {
    padding-top: 32px;
    column-count: 1;
}
#offer-section-mobile {
    padding: 0 16px 16px 16px;
}
@media  only screen and (min-width: 768px) {
    .card-box {
        padding-top: 32px;
        column-count: 2;
    }
}
@media  only screen and (min-width: 1024px) {
    .card-box {
        column-count: 4;
    }
    .ant-card  {
        max-width: 90%;
        height: 350px;
    }
    .ant-card:first-child {
        transform: translateY(84px);
    }
    .ant-card:last-child {
        transform: translateY(84px);
    }
}