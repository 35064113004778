#copy-main-container {
    padding-top: 0;
    background-color: #1788fc;
}
.section-text-center {
    margin: 0 !important;
    font-family: 'DM Sans';
    color: white !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    text-align: center;
}
#copy-container {
    padding: 16px;
}
@media only screen and (min-width: 1024px) {

}