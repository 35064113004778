#integration-container {
    padding: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.integration-avatar {
    border-radius: 16px;
    background: #0085FF !important;
}

.hexagon-container {
    display: flex;
    flex-wrap: wrap;
    margin: 50px auto;
    min-width: 100%;
    gap: 40px 20px;
    justify-content: center;
}

.hexagon:nth-child(7) {
    display: none;
}


@media  only screen and (min-width: 1024px) {
    #integration-container {
        flex-direction: row;
        padding: 16px 56px 16px 56px;
    }
    .section-paragraph {
        width: 90%;
    }
    
    .hexagon-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        column-gap: 50px;
        row-gap: 50px;
        margin: 50px auto;
        min-width: 500px;
    }
      
      .hexagon {
        position: relative;
        overflow: hidden;
        width: 100px;
        height: 100px;
    }
      
      .hexagon:nth-child(1) {
        grid-column: 2 / 2;
        grid-row: 1 / 3;
        transform: translateX(-32%);
    }
      
      .hexagon:nth-child(2) {
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        transform: translateX(87%);
    }
      
      .hexagon:nth-child(3) {
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }
      
      .hexagon:nth-child(4) {
        grid-column: 2 / 3;
        grid-row: 2/ 3;
        transform: translateX(45%);
    }
      
      .hexagon:nth-child(5) {
        grid-column: 2/ 2;
        grid-row: 3 / 4;
        transform: translateX(-32%);
      }
      
      .hexagon:nth-child(6) {
        grid-column: 2/2;
        transform: translateX(120%);
        grid-row: 3 / 3;
    }
      .hexagon:nth-child(7) {
        grid-column: 2/4;
        grid-row: 1/4;
        transform: translateX(120%);
        display: flex;
    }    
}