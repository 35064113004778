#customer-container {
    padding: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
#customer-main-container {
    background-color: #F7FBFF;
    margin-top: 10%;
    padding-top: 0;
}
.section-title-left {
    text-transform: uppercase;
    font-size: 20px;
    color: #1890ff !important;
    font-weight: 500 !important;
    text-align: center;
    font-family: 'DM Sans';
}
.section-caption-left {
    text-align: center;
    color: #001E3A !important;
    font-family: 'DM Sans';
    font-size: 26px !important;
    margin-top: 8px !important;
}
.section-paragraph {
    font-size: 18px !important;
    text-align: center;
    color: #3E566C !important;
    font-family: 'DM Sans';
}
.customer-image-animation {
    min-width: 50%;
}
.customer-content-texts {
    min-width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
@media  only screen and (min-width: 1024px) {
    .section-caption-left {
        text-align: left;
        color: #001E3A !important;
        width: 80%;
        font-size: 26px !important;
        margin-top: 8px !important;
    }
    .section-title-left {
        text-align: left;
    }
    .section-paragraph {
        width: 75%;
        text-align: justify;
    }
    #customer-container {
        flex-direction: row;
    }
    .customer-content-texts {
        display: flex;
        flex-direction: column;
        align-items: start;
    }
}