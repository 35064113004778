.ant-menu-overflow {
    justify-content: end;
}
.ant-menu-light {
    color: #001E3A;
    background: transparent !important;
    font-family: 'DM Sans';
    width: 100%;
}
.ant-menu-horizontal {
    border: none;
}
.ant-menu {
   font-size: 16px;
}
.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 30px 8px 8px;
}