html, body {
  font-family: 'DM Sans', sans-serif;
}
@font-face {
  font-family: 'DM Sans';
  src: local('DM Sans'), url(./fonts/DMSans-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'DM Sans';
  font-weight: 700;
  src: local('DM Sans'), url(./fonts/DMSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'DM Sans';
  font-weight: 700;
  font-style: italic;
  src: local('DM Sans'), url(./fonts/DMSans-BoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'DM Sans';
  font-weight: 500;
  font-style: italic;
  src: local('DM Sans'), url(./fonts/DMSans-MediumItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'DM Sans';
  font-weight: 500;
  src: local('DM Sans'), url(./fonts/DMSans-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'DM Sans';
  font-style: italic;
  src: local('DM Sans'), url(./fonts/DMSans-Italic.ttf) format('truetype');
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}