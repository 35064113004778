#testimonial-container {
   background-image: url('../../images/reviews.svg');
   background-size: contain;
}
.main-testimonial-container {
    padding: 16px;
}
.carousel-card {
    min-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    margin-top: 32px;
}
.comment {
    width: 70% !important;
    transform: translate(230%, -70%);
}
.avatar {
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-bottom: 32px;
}
.avatar > img {
    width: 100%;
    height: 100%;
    margin: 0;
}
.testimonial {
    color: #3E566C !important;
    font-size: 18px;
    text-align: center;
    font-family: 'DM Sans';
    width: 70%;
}
.testimonial-infos {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.name {
    color: #011f39;
    font-size: 16px;
    font-weight: bold;
    font-family: 'DM Sans';
}
.position {
    color: #3E566C !important;
    font-family: 'DM Sans';
    font-weight: 500;
}
.carousel-testimonial {
    width: 90%;
    height: 100%;
    margin: auto;
}
@media  only screen and (min-width: 1024px) {
    #testimonial-container {
        background-image: url('../../images/reviewsDesktop.png');
        background-size: 92%;
        background-repeat: no-repeat;
        background-position: center; 
    }
    .main-testimonial-container {
        padding: 16px 16px 300px 16px ;
    }
}